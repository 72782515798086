import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

export async function signUpMerchant(data, dispatch, token) {
  axios
    .post(`${baseURL}/merchants`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: "SIGNUP_SUCCESS",
        payload: true,
      });
      // alert('Signup successful Login Now')
    })
    .catch((e) => {
      console.log(e);
      dispatch({
        type: "SIGNUP_FAILURE",
        payload: false,
      });
      alert("signup failed");
    });
}

export const allPosList = (dispatch) => {
  axios
    .get(`${baseURL}/pos`)
    .then((res) => {
      dispatch({
        type: "POS_LIST",
        payload: res.data.data.records,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const findMerchant = async(key) => {
  const resData = await axios.get(
    `${baseURL}/square/webhook?model=merchants&token=${key}`
  );
  const merchantData = await resData.data;
  return merchantData
}
