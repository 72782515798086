// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCeCxe-rJwDTDhjp4FtjYHYBszofFY7Ca4",
  authDomain: "wambam-app-d5d7d.firebaseapp.com",
  projectId: "wambam-app-d5d7d",
  storageBucket: "wambam-app-d5d7d.appspot.com",
  messagingSenderId: "658760300617",
  appId: "1:658760300617:web:72e8ba747ede5aa407a798",
  measurementId: "G-4E559M7PKH"
};

const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);
