
import AuthPage from '../pages/AuthPage'
import Dashboard from '../pages/Dashboard'
import { Routes, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import SignupGuide from '../pages/SignupGuide'


function Router() {

  return (
    <>
        <Routes>
          <Route path="/authentication" element={<AuthPage />} />
          <Route path="/signup-instruction" element={<SignupGuide />} />
          <Route path="/" element={<PrivateRoute><Dashboard/></PrivateRoute>} />
        </Routes>
    </>
  )
}

export default Router