import React from "react";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { userIsLoggedIn } from "../redux/auth/Action";
import { useDispatch } from "react-redux";
// import { allpost } from '../redux/auth/Action';
import { Link } from "react-router-dom";

const Navbar = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const data = useSelector((e) => {
  //   return e.reducer;
  // });

  // function signoff() {
  //   localStorage.removeItem("cartoken");
  //   dispatch({
  //     type: "LOGOUT",
  //     payload: false,
  //   });
  //   navigate("/authentication");
  // }

  // useEffect(() => {
  //   if (data.token) {
  //     userIsLoggedIn(data.token, dispatch);
  //   }
  //   // if (data.token) {
  //   //   allpost(dispatch)
  //   // }
  //   if (!data.token) {
  //     navigate("/authentication");
  //   }
  // }, [data.token]);

  return (
    <>
      {/* navbar ////// */}
      <div className="w-full bg-[#4070f4] h-16 flex pl-[60px] justify-start items-center fixed top-[0]">
        <div className="flex w-7/12 items-center">
          <img src="./../../images/logo.png" className="w-[60px] mr-[20px]" />
          <h1 className="text-4xl font-bold">
            <Link to="/"  className="text-[24px]">WAM BAM</Link>
          </h1>
        </div>
      </div>
      {/* navbar /////// */}
    </>
  );
};

export default Navbar;
