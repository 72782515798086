import React from "react";

const Dashboard = () => {


  // bg-gradient-to-r from-black via-blue-500 to-pink-700 ...

  return (
    <div className=" min-h-screen w-screen flex justify-center">
      Wam Bam Dashboard !
    </div>
  );
}

export default Dashboard;
