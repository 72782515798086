import Signup from "../components/Signup";
import Login from "../components/Login";
import { useEffect, useState } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { firebaseAuth } from "../utils/firebase-config";

const AuthPage = () => {
  const [signup, setSignup] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  const email = localStorage.getItem("email");
  function changeToLogin() {
    setSignup(false);
  }
  function changeToSignup() {
    setSignup(true);
  }

  function signOff() {
    signOut(firebaseAuth);
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    setIsLogin(false);
    // localStorage.removeItem('subscribe')
  }

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (currentUser) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="flex h-auto min-h-screen justify-between bg-[#4070f4]">
        {loading ? (
          <div className="w-3/5 flex justify-center items-center bg-[#4070f4]">
            <img
              className="h-[4em]"
              src="https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/loading-gif-png-5.gif"
              alt=""
            />
          </div>
        ) : isLogin === false ? (
          <div className="w-3/5 flex justify-center items-center bg-[#4070f4] ">
            {signup && (
              <Signup changeToLogin={changeToLogin} setIsLogin={setIsLogin} />
            )}
            {!signup && (
              <Login changeToSignup={changeToSignup} setIsLogin={setIsLogin} />
            )}
          </div>
        ) : (
          <>
            <div className="w-3/5 flex justify-center items-center bg-[#4070f4]">
              <div className="w-4/6 flex flex-col text-center justify-center items-center gap-[20px]">
                <img
                  className="h-[14em]"
                  src="https://www.lappymaker.com/images/greentick-unscreen.gif"
                  alt=""
                />
                <h1 className="text-5xl font-bold text-white">
                  Congratulations !
                </h1>
                <h1 className="text-2xl font-bold text-white">
                You have successfully joined.
                </h1>
                <button
                  className="w-1/5 bg-white text-[#4070f4]  p-2 w-full rounded-xl font-bold cursor-pointer"
                  onClick={() => signOff()}
                >
                  Sign out
                </button>
              </div>
            </div>
          </>
        )}
        <div className="bg-[#4070f4] w-3/5 flex justify-left items-center">
          <h1 className="text-9xl font-bold text-white">
             Wam Bam Merchant !
          </h1>
        </div>
      </div>
    </>
  );
};

export default AuthPage;
