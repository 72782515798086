import React from "react";

const SignupGuide = () => {
  // bg-gradient-to-r from-black via-blue-500 to-pink-700 ...

  return (
    <div className="max-w-4xl mx-auto p-6 mt-[80px]">
      <h1 className="text-4xl font-bold mb-4">
        Wam Bam Helps You Sign Up Effortlessly—No Worries, We've Got You
        Covered!
      </h1>
      <h2 className="text-2xl font-bold mb-4 mt-[40px]">
        Here are the steps to find the Access Key/Token
      </h2>

      {/* Step 1 */}
      <div className="mb-6">
        <p className="mb-2">
          1. Open{" "}
          <a
            href="https://developer.squareup.com/console/en/apps"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            https://developer.squareup.com/console/en/apps
          </a>{" "}
          and log in with your credentials (merchants need to log in with their
          credentials to find an access token to register in Wam Bam).
        </p>
        <img
          src="./../../images/step1.png"
          alt="Login screen"
          className="border border-gray-200 rounded-lg shadow"
        />
      </div>

      {/* Step 2 */}
      <div className="mb-6">
        <p className="mb-2">
          2. If you see the screen below (you can skip this), skip this by
          clicking on <strong>Remind me next time</strong> &gt;{" "}
          <strong>Continue to Square</strong>.
        </p>
        <img
          src="./../../images/step2.png"
          alt="Skip screen"
          className="border border-gray-200 rounded-lg shadow"
        />
        <img
          src="./../../images/step3.png"
          alt="Skip confirmation"
          className="border border-gray-200 rounded-lg shadow mt-4"
        />
      </div>

      {/* Step 3 */}
      <div className="mb-6">
        <p className="mb-2">
          3. After signing in, you can see your Developer dashboard. Click on
          the <strong>Open</strong> button below the Wambam applications.
        </p>
        <img
          src="./../../images/step4.png"
          alt="Developer dashboard"
          className="border border-gray-200 rounded-lg shadow"
        />
      </div>

      {/* Step 4 */}
      <div className="mb-6">
        <p className="mb-2">
          4. After clicking on the <strong>Open</strong> button displayed by the
          Sandbox screen, copy your <strong>Sandbox access token</strong> (first
          click on the show button, then copy) to sign up as a merchant in Wam
          Bam.
        </p>
        <img
          src="./../../images/step5.png"
          alt="Access token screen"
          className="border border-gray-200 rounded-lg shadow"
        />
        <img
          src="./../../images/step6.png"
          alt="Access token screen"
          className="border border-gray-200 rounded-lg shadow"
        />
        <p className="mt-2 text-sm text-gray-600">
          *You can use your <strong>production access token</strong> to join
          with the production store.
        </p>
      </div>
    </div>
  );
};

export default SignupGuide;
