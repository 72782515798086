const initialState = {
  isAuth: false,
  posList:[],
  };
  
  const reducer = (state = initialState,action) => {
    switch(action.type){
        case "POS_LIST":{
          return{
            ...state,
            posList:action.payload,
          }
      
        }
        case "LOGOUT":{
          return{
            ...state,
            isAuth:true,
          }
        }
        default:
        return state;
      }
  };
  
  export { reducer };