import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allPosList, findMerchant, signUpMerchant } from "../redux/auth/Action";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from "../utils/firebase-config";

const Signup = ({ changeToLogin, setIsLogin }) => {
  // const [isLogin, setIsLogin] = useState(true);
  const [token, setToken] = useState();
  const [merchant, setMerchant] = useState();
  const [accessTokenError, setAccessTokenError] = useState(true);
  const [pos, setPos] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    pos: "",
    ABN: "",
    accessToken: "",
    businessName: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${key} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const dispatch = useDispatch();
  const posList = useSelector((e) => {
    return e.reducer.posList;
  });
  function getList(e) {
    let { name, value } = e;
    setFormData({
      ...formData,
      ...merchant,
      [name]: value,
      posId: pos?.id,
    });
  }

  function submitForm(event) {
    validateForm();
    event.preventDefault();
    if (!errors && formData && merchant) {
      handleSignUp();
    }
  }

  const handleSignUp = async () => {
    try {
      const { email, password } = formData;
      let user = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const idToken = await user._tokenResponse.idToken;
      const merchant = await signUpMerchant(formData, dispatch, idToken);
      if (merchant && user) {
        localStorage.setItem("userId", user._tokenResponse.localId);
        localStorage.setItem("email", email);
        localStorage.setItem("Token", idToken);
        setIsLogin(true);
        alert("User signed up successfully");
      }
    } catch (error) {
      alert(error);
    }
  };

  async function getMerchant(key) {
    setToken(key);
    if (!pos) {
      return;
    }
    if (!key) {
      setAccessTokenError("");
      return;
    }
    setAccessTokenError("");
    try {
      const merchantData = await findMerchant(key);
      if (merchantData) {
        setMerchant({ ...merchantData });
        setTimeout(() => {
          setToken(true);
        }, 2000);
      }
    } catch (e) {
      setAccessTokenError("invalid access token");
      setMerchant("");
      setToken(false);
    }
  }

  useEffect(() => {
    allPosList(dispatch);
    setErrors("");
  }, []);

  useEffect(() => {
    if (token && pos) {
      getMerchant(token);
    }
  }, [pos]);

  return (
    <>
      <div className=" w-4/6 bg-[#ffffff] p-12 rounded-md min-h-[80%] mt-[100px] mb-[100px]" >
        <div>
          <h1 className="font-bold text-3xl ">Sign Up</h1>
        </div>
        <div>
          <p className="text-sm mt-1 font-medium">Create a merchant account.</p>
        </div>
        <div className="flex justify-between mb-4 mt-4 "></div>
        <div className="bg-[#ffffff] flex-column justify-center items-center text-sm rounded-xl w-full">
          <form className=" flex-column font-medium w-full ">
            <p className="mb-1">Owner Name</p>
            <input
              type="text"
              placeholder="Enter your owner name"
              className="border-2 border-black p-2 rounded-xl mb-3 w-full "
              autoComplete="off"
              name="name"
              onChange={(e) => getList(e.target)}
              maxLength="30"
            />
            {errors.name && (
              <p className="text-red-500 mt-[-12px]">{errors.name}</p>
            )}
            <p className="mb-1">Email Address</p>
            <input
              type="email"
              placeholder="Enter your email"
              className="border-2 border-black p-2 rounded-xl mb-3 w-full "
              autoComplete="off"
              name="email"
              onChange={(e) => getList(e.target)}
              maxLength="30"
            />
            {errors.email && (
              <p className="text-red-500 mt-[-12px]">{errors.email}</p>
            )}
            <p className="mb-1">Select Your POS</p>
            <select
              placeholder="Select Your POS"
              className="border-2 border-black p-2 rounded-xl mb-6 w-full"
              autoComplete="off"
              name="pos"
              onChange={(e) => (
                setPos(posList[e.target.value]), getList(e.target)
              )}
            >
              <option value="" disabled selected>
                Select Your POS
              </option>
              {posList.map((pos, i) => {
                return (
                  <>
                    <option value={i} key={i}>
                      {pos.name}
                    </option>
                  </>
                );
              })}
            </select>
            {token && !pos ? (
              <p className="text-red-500 mt-[-12px]">select your pos</p>
            ) : (
              <p className="text-red-500 mt-[-12px]">{errors.pos}</p>
            )}
            <p className="mb-1">Enter Your ABN</p>
            <input
              type="text"
              placeholder="Enter your ABN"
              className="border-2 border-black p-2 rounded-xl mb-3 w-full "
              autoComplete="off"
              name="ABN"
              inputMode="numeric"
              pattern="\d*"
              onChange={(e) => getList(e.target)}
              maxLength="11"
            />
            {errors.ABN && (
              <p className="text-red-500 mt-[-12px]">{errors.ABN}</p>
            )}
            <p className="mb-1">
              Enter Your Access Token &nbsp; 
              <a
                href="/signup-instruction"
                target="_blank"
                rel="noopener noreferrer"
                className="fa-solid text-[0.50rem] text-blue-700"
              >
                ( find your access key <i className="fa-solid fa-key text-[0.65rem] text-blue-900"></i> )
                {/* <i className="fa-solid fa-key text-[0.65rem] text-blue-400"></i> */}
              </a>
            </p>
            <input
              type="text"
              placeholder="Enter your access token"
              className="border-2 border-black p-2 rounded-xl mb-6 w-full "
              autoComplete="off"
              name="accessToken"
              onChange={(e) => (
                getMerchant(e.target.value),
                getList(e.target),
                setToken(e.target.value)
              )}
            />
            {!merchant ? (
              <p className="text-red-500 mt-[-12px]">{accessTokenError}</p>
            ) : (
              <p className="text-red-500 mt-[-12px]">{errors.accessToken}</p>
            )}
            {token && merchant && (
              <>
                <p className="mb-1">Business Name</p>
                <input
                  type="select"
                  placeholder="Your business name"
                  className="border-2 border-black p-2 rounded-xl mb-6 w-full "
                  autoComplete="off"
                  name="businessName"
                  value={merchant?.business_name}
                  onChange={(e) => getList(e.target)}
                  disabled={true}
                />
              </>
            )}
            <p className="mb-1">Password</p>
            <input
              type="password"
              placeholder="Enter your password"
              className="border-2 border-black p-2 rounded-xl mb-5 w-full "
              autoComplete="off"
              name="password"
              onChange={(e) => getList(e.target)}
              maxLength="16"
            />
            {errors.password && (
              <p className="text-red-500 mt-[-20px]">{errors.password}</p>
            )}
            <button
              className="bg-[#4070f4] text-white p-2 w-full rounded-xl font-bold cursor-pointer"
              onClick={submitForm}
            >
              Sign Up
            </button>
          </form>
        </div>
        <p className="mt-4">
          Already have an account ?{" "}
          <span
            className="text-sky-700 font-bold cursor-pointer"
            onClick={() => changeToLogin()}
          >
            Log In
          </span>{" "}
          
        </p>
      </div>
    </>
  );
};

export default Signup;
