import React from "react";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from "../utils/firebase-config";
// import { userIsLoggedIn } from "../redux/auth/Action";

function Login({ changeToSignup, setIsLogin }) {
  const [user, setUser] = useState({});
  function getList(e) {
    let { name, value } = e;
    setUser({ ...user, [name]: value });
  }
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      let merchant = await signInWithEmailAndPassword(
        firebaseAuth,
        user.email,
        user.password
      );
      console.log(merchant)
      const idToken = await merchant._tokenResponse.idToken;
      localStorage.setItem("Token", idToken);
      setIsLogin(true);
      localStorage.setItem("userId", merchant._tokenResponse.localId);
      localStorage.setItem("email", user.email);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div className=" w-4/6 bg-[#ffffff] p-12 rounded-md">
        <div>
          <h1 className="font-bold text-3xl ">Sign In</h1>
        </div>
        <div>
          <p className="text-sm mt-1 font-medium">Sign in to your account.</p>
        </div>
        <div className="flex justify-between mb-4 mt-4 "></div>
        <div className="bg-[#ffffff] flex-column justify-center items-center text-sm rounded-md w-full">
          <form className=" flex-column font-medium w-full ">
            <p className="mb-1">Email Address</p>
            <input
              type="email"
              placeholder="Enter your email"
              className="border-2 border-black  p-2 rounded-xl mb-3 w-full "
              autoComplete="off"
              name="email"
              onChange={(e) => getList(e.target)}
            />
            <p className="mb-1">Password</p>
            <input
              type="password"
              placeholder="Enter your password"
              className="border-2 border-black  p-2 rounded-xl mb-5 w-full "
              autoComplete="off"
              name="password"
              onChange={(e) => getList(e.target)}
            />
            <p className="text-sky-700 font-bold cursor-pointer">
              Forgot password ?
            </p>
            <button
              className="bg-[#4070f4] text-white p-2 w-full rounded-xl font-bold mt-4 cursor-pointer"
              onClick={handleLogin}
            >
              Sign In
            </button>
          </form>
        </div>
        <p className="mt-4">
          Don't have an account ?{" "}
          <span
            className="text-sky-700 font-medium cursor-pointer"
            onClick={() => changeToSignup()}
          >
            Register here
          </span>{" "}
        </p>
      </div>
    </>
  );
}

export default Login;
